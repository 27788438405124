.ce_image {
    &.element--55a84e {
        border-bottom: 1px solid black;
    }

    &.element--54fe8c {
        border-bottom: 1px solid black;
    }

    &.element--89be40 {
        width: 100%;

        img {
            width: 100%;
        }
    }
}
