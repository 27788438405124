.nav--main {
    text-transform: uppercase;
    color: var(--blue);
    transition: color 0.2s;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    ul.level_1 {
        & > li {
            & > a,
            & > span,
            & > strong {
                display: block;
                font-family: var(--haas-display);
                font-weight: 600;
                font-size: inherit;
                line-height: 1.2;
            }
        }
    }

    body.page--index &,
    body.page--road-to-la28 & {
        color: white;
    }

    body.scroll--scrolled & {
        color: var(--blue);
    }
}

@include menu('max') {
    .nav--main {
        position: absolute;
        right: -400px;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        transition: transform 0.2s;
        max-width: 400px;

        body.show-menu & {
            transform: translateX(-400px);
        }

        ul.level_1 {
            border: 1px solid black;
            background-color: white;

            & > li {
                & > a,
                & > span,
                & > strong {
                    font-size: 30px;
                    padding: 28px 35px;
                    display: block;
                    color: black;
                    text-align: right;
                }

                & + li {
                    border-top: 1px solid black;
                }
            }
        }
    }
}

@include menu() {
    .nav--main {
        ul.level_1 {
            display: flex;

            & > li {
                & > a,
                & > span,
                & > strong {
                    font-size: vwmax(20px);
                    padding: vwmax(52px) vwmax(20px);
                    color: inherit;
                }

                &:nth-child(2) {
                    margin-right: auto;
                }
            }
        }
    }
}
