#header {
    color: var(--blue);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;

    & > .inside {
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
    }
}
