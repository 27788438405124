.menu-button {
    font-size: 20px;
    outline: 0;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    text-transform: uppercase;
    padding: 17px;
    line-height: 1.2;
    color: inherit;
    font-weight: 600;
    font-family: var(--haas-display);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    max-width: 100px;

    &:before {
        @include sprite('../../images/x.svg');
        width: 18.015px;
        height: 18.015px;
        content: '';
        display: block;
        position: absolute;
        right: 17px;
        top: 0;
        bottom: 0;
        margin: auto;
        opacity: 0;
        visibility: hidden;
    }

    body.show-menu & {
        text-indent: 200px;

        &:before {
            opacity: 1;
            visibility: visible;
        }
    }

    @include menu() {
        display: none;
    }
}
