/**
 * Basic typography defintions
 */
html { 
    font-family: var(--haas-text);
    font-size: var(--font-size-mobile);

    @include desktop() {
        font-size: var(--font-size-desktop);
    }
}

a {
    color: inherit;
    text-decoration: none;
    font-size: rem(15px);
}

b, strong {
    font-family: var(--haas-display);
    font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--haas-display);

    a {
        &, &:hover {
            color: inherit;
            text-decoration: inherit;
        }
    }
}

h1 {
    @include h1();
}

h2 {
    @include h2();
}

h3 {
    @include h3();
}

h4 {
    @include h4();
}

h5 {
    @include h5();
}

h6 {
    @include h6();
}

ul,
ol,
p {
    @include typo();
}

ul,
ol {
    margin: 1em 0;
}

/* headline styles */
h1.headline--h1,
h2.headline--h1,
h3.headline--h1,
.headline--h1 h1,
.headline--h1 h2,
.headline--h1 h3 {
    @include h1();
}

h1.headline--h2,
h2.headline--h2,
h3.headline--h2,
.headline--h2 h1,
.headline--h2 h2,
.headline--h2 h3 {
    @include h2();
}

h1.headline--h3,
h2.headline--h3,
h3.headline--h3,
.headline--h3 h1,
.headline--h3 h2,
.headline--h3 h3 {
    @include h3();
}

/* margins */
@mixin margin-generator($type, $margin) {
    @if $type == '' {
        .margin--#{$margin} {
            @include margin-type('top', $margin, true);
            @include margin-type('bottom', $margin, true);
        }
    } @else {
        .margin--#{$type}--#{$margin} {
            @include margin-type($type, $margin, true);
        }
    }
}

@mixin margin($margin) {
    @include margin-generator('', $margin);
    @include margin-generator('top', $margin);
    @include margin-generator('bottom', $margin);
}

@include margin(0);
@include margin(1);
@include margin(2);
@include margin(4);
@include margin(8);
@include margin(14);
@include margin(22);
@include margin(32);
@include margin(44);
@include margin(58);

.image--center figure img {
    margin: auto;
}

.image--left figure img {
    margin-left: 0;
    margin-right: auto;
}

.image--right figure img {
    margin-right: 0;
    margin-left: auto;
}

.text--center {
    text-align: center;
}

.text--left {
    text-align: left;
}

.text--right {
    text-align: right;
}

.text--blue {
    color: var(--blue);
}

.text--medium {
    font-size: rem(50px);
}

.text--large {
    font-size: rem(80px);
}
.text--small {
    font-size: rem(15px);
}
