@mixin h1() {
    font-family: var(--haas-display);
    font-size: 30px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 600;

    @include desktop() {
        font-size: vwmax(50px);
    }
}

@mixin h2() {
    font-family: var(--haas-display);
    font-size: 20px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 600;

    @include desktop() {
        font-size: vwmax(30px);
    }
}

@mixin h3() {

}

@mixin h4() {

}

@mixin h5() {

}

@mixin h6() {

}

@mixin typo() {
    line-height: 1.35;
}

@mixin margin-type($type, $margin, $important: false) {
    @if $margin > 1 {
        @if $important {
            margin-#{$type}: #{$margin * 0.5}rem !important;

            @include tablet() {
                margin-#{$type}: #{$margin * 0.75}rem !important;
            }

            @include desktop() {
                margin-#{$type}: #{$margin}rem !important;
            }
        } @else {
            margin-#{$type}: #{$margin * 0.5}rem;

            @include tablet() {
                margin-#{$type}: #{$margin * 0.75}rem;
            }

            @include desktop() {
                margin-#{$type}: #{$margin}rem;
            }
        }
    } @else {
        @if $important {
            margin-#{$type}: #{$margin}rem !important;
        } @else {
            margin-#{$type}: #{$margin}rem;
        }
    }
}

@mixin checker() {
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 10px 10px;
    background-position: 0 0, 0 5px, 5px -5px, -5px 0px;
}

/* paragraph reset */
@mixin pr() {
    p:first-child {
        margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}
