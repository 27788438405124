/**
 * Basic styling, basic font size, CSS variables etc.
 */
:root {
    --haas-display: 'neue-haas-grotesk-display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --haas-text: 'neue-haas-grotesk-text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    --blue: #0096CF;
    --font-size-mobile: #{$text-size-mobile};
    --font-size-desktop: #{vwmax($text-size-desktop)};
    --outer-padding: 16px;
}

@include desktop() {
    :root {
        --outer-padding: #{vwmax(50px)};
    }
}

html {
    scroll-behavior: smooth;
}

body, html {
    height: 100%;
}

body { 
    overflow: hidden;
    overflow-y: scroll;
}

figure {
    display: block;
    max-width: 100%;
    margin: 0;
    box-sizing: border-box;
}

main {
    display: block;
}

img {
    border: 0;
}

video {
    max-width: 100%;
    height: auto;
}

figure img {
    width: auto;
    height: auto;
    max-width:100%;
    display: block;
    margin: 0;
}

figure figcaption {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

iframe {
    border:0;
}

.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.wow {
    visibility: hidden;
}

.inside {
    position:relative;
}

.ce_player video {
    width: 100%;
    height: auto;
}

/* default 16:9 aspect ratio */
.ce_youtube, .ce_vimeo {
    .video-wrapper,
    .video_container {
        position: relative;
        padding-bottom:56.25%;
        height:0;
    }

    .video-wrapper > *,
    .video_container > :not(.responsive):not(.caption),
    .video_container > .responsive > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        z-index: 6;
    }
}

.hide-desktop {
    @include tablet() {
        display: none !important;
    }
}

.hide-mobile {
    @include tablet('max') {
        display: none !important;
    }
}

.hide-all {
    display: none !important;
}

.ce_image {
    &.hide-desktop {
        width: 100%;
        height: 100%;

        figure {
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
