@use 'sass:math';

@mixin column-classes($max-columns, $class-name) {
    @for $cols from 1 through $max-columns {
        @for $span from 1 through $cols {
            &.-#{unquote($class-name)}-col-#{$cols}-#{$span} {
                grid-column: span #{$span};
            }
        }
    }
}

.rs-columns {
    display: grid;
    width: 100%;

    @for $i from 1 through 6 {
        &.columns--small--#{$i} {
            grid-template-columns: repeat(#{$i}, 1fr);
        }
    }

    @include tablet() {
        border-bottom: 1px solid black;

        &:last-child {
            border-bottom: 0;
        }

        @for $i from 1 through 6 {
            &.columns--medium--#{$i} {
                grid-template-columns: repeat(#{$i}, 1fr);
            }
        }
    }

    @include desktop() {
        @for $i from 1 through 6 {
            &.columns--large--#{$i} {
                grid-template-columns: repeat(#{$i}, 1fr);
            }
        }
    }
}

.rs-column {
    @include column-classes(6, 'small');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-left: 1px solid black;
    border-bottom: 1px solid black;

    .rs-column & {
        border-bottom: 0;
    }

    & > .rs-column {
        height: 100%;
    }

    &.-small-first {
        border-left: 0;
    }

    @include tablet('max') {
        body.page--road-to-la28 & {
            min-height: vw(278px);
        }

        body.page--andreas-ernhofer & {
            min-height: vw(278px);
        }
        body.page--partner & {
            min-height: vw(278px);
        }
    }

    @include tablet() {
        @include column-classes(6, 'medium');
        border-bottom: 0;

        &.-small-first {
            border-left: 1px solid black;
        }

        &.-medium-first {
            border-left: 0;
        }
    }

    @include desktop() {
        @include column-classes(6, 'large');

        &.-small-first {
            border-left: 1px solid black;
        }

        &.-medium-first {
            border-left: 1px solid black;
        }

        &.-large-first {
            border-left: 0;
        }

        body.page--shop & {
            min-height: vwmax(1200px);
        }
        body.page--wettkampf & {
            min-height: vwmax(620px);
        }
        body.page--partner & {
            min-height: vwmax(620px);
        }
    }
}

body.page--partner{
    .border-left-mobile{
        border-left: 1px solid black;
    }
    .min-height-headline{
        min-height: 10vw;
    }
    
    .logo{
        figure{
            max-width: 90%;
            margin: 0 auto;

            @include desktop(){
                max-width: 100%;
            }
        }
    }
}