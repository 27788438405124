#footer {
    & > .inside {
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        padding: 22px 0;        

        @include tablet() {
            border-top: 1px solid black;
        }

        @include desktop() {
            padding: vwmax(80px) 0;
        }
    }
}
