.ce_rs_column_start {
    &.element--472e91 {
        order: 2;

        @include desktop() {
            order: -1;
        }
    }

    &.element--bf22a5,
    &.element--d1ca9e {
        @include desktop('max') {
            border-left: 0;
        }
    }

    &.element--d83343,
    &.element--ec80b3 {
        @include desktop('max') {
            min-height: 0 !important;
        }
    }
}
