.ce_text {
    .rs-column & {
        @include pr();
        margin: 32px 16px;
    
        h1, h2, h3, h4, h5, h6 {
            &:first-child {
                margin-top: 0;
            }
        }
    
        @include desktop() {
            margin: vwmax(60px) vwmax(50px);
        }
    }

    .rs-column.-small-col-2-1 & {
        margin-left: 5px;
        margin-right: 5px;

        @include desktop() {
            margin: vwmax(60px) vwmax(50px);
        }
    }

    &.element--815e94 {
        text-align: center;

        @include desktop('max') {
            margin-top: 50px;
            margin-bottom: 40px;
            max-width: 300px;
            margin-left: auto;
            margin-right: auto;
        }

        @include desktop() {
            text-align: left;
            margin: 0 vwmax(193px);
        }
    }

    &.element--d0ed24 {
        @include desktop() {
            max-width: vwmax(531px);

            h1 {
                text-align: center;
            }
        }
    }

    &.text--quote {
        p, a {
            font-size: 1.5em;
            font-family: var(--haas-display);
            font-weight: 600;
            line-height: normal;
        }

        p:last-child {
            font-size: inherit;
        }
    }

    &.text--medium,
    &.text--small-medium,
    &.text--blue-small-medium {
        font-size: rem(50px);
        font-family: var(--haas-display);
        font-weight: 600;
        text-align: center;
    }

    &.text--small-medium,
    &.text--blue-small-medium {
        p:first-child {
            font-size: rem(25px);
            margin-bottom: 0;

            & + p {
                margin-top: 0;
            }
        }
    }

    &.text--blue-small-medium {
        p:first-child {
            color: var(--blue);
        }
    }

    &.text--small-medium-small {
        font-size: rem(50px);
        font-family: var(--haas-display);
        font-weight: 600;
        text-align: center;

        p {
            line-height: normal;
            margin-bottom: 0;
            margin-top: 0;

            &:first-child{
                font-size: rem(20px);
                font-weight: 500;
            }

            &:last-child{
                font-size: rem(22px);
            }
        }
    }

    body.page--impressum .mod_article > &,
    body.page--datenschutz .mod_article > & {
        max-width: vwmax(962px);
        margin: rem(90px) auto;
        padding: 0 var(--outer-padding);

        b, strong {
            font-family: inherit;
            font-weight: 500;
        }
    }

    &.element--05fa75 {
        @include pr();
        text-align: center;
        font-size: rem(70px);
        font-weight: 600;
        font-family: var(--haas-display);

        p {
            line-height: 1.1;
        }

        @include desktop() {
            font-size: vwmax(70px);
            max-width: vwmax(500px);
        }
    }

    &.countdown {
        p {
            margin: 0;
        }
    }

    p > a:first-child {
        font-size: inherit;
    }
}
