.nav--footer {
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    font-family: var(--haas-display);

    @include desktop() {
        font-size: vwmax(15px);
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    li {
        & > a,
        & > span,
        & > strong {
            display: block;
            color: inherit;
            text-decoration: none;
            font-weight: inherit;
            font-family: inherit;
            font-size: inherit;
            padding: 0.1333em 0;
        }
    }
}
