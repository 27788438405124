.ce_form {
    margin: 2em 0;

    &.element--095092 {
        @include desktop() {
            max-width: vwmax(531px);
        }
    }

    input:not([type="radio"]):not([type="checkbox"]),
    textarea,
    select {
        border: 0;
        outline: 0;
        border-radius: 0;
        display: block;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid black;
        padding: 0;
        font-size: inherit;
        height: 1em;
    }

    textarea {
        border: 1px solid black;
        width: 100%;
        box-sizing: border-box;
        height: auto;
    }

    [type="submit"] {
        border: 1px solid black;
        outline: 0;
        border-radius: 0;
        background-color: transparent;
        text-transform: uppercase;
        font-size: 20px;
        font-family: var(--haas-display);
        line-height: 1.2;
        padding: 0 30px;

        @include desktop() {
            font-size: vwmax(20px);
            padding: 0 vwmax(30px);
        }
    }

    .widget {
        display: flex;
        align-items: baseline;

        & + .widget {
            margin-top: 15px;

            &-submit {
                margin-top: 44px;
            }

            @include desktop() {
                margin-top: vwmax(15px);

                &-submit {
                    margin-top: vwmax(44px);
                }
            }
        }

        label {
            display: block;
            min-width: 80px;
            font-size: 20px;
            text-transform: uppercase;
            font-family: var(--haas-display);
            font-weight: 600;

            @include desktop() {
                min-width: vwmax(80px);
                font-size: vwmax(20px);
            }
        }

        &-textarea {
            display: block;

            label {
                margin-bottom: 6px;

                @include desktop() {
                    margin-bottom: vwmax(6px);
                }
            }
        }

        &-submit {
            justify-content: center;
        }
    }
}
