#container {
    width: 100%;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;

    body.page--404 &,
    body.page--503 &,
    body.page--error {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
